<template>
  <HubModal
    id="dashboard-touchpoint-filter-modal"
    :title="$t('organisations.touchpoint', 2)"
    confirm-text="filters.applyFilters"
    :translate-title="false"
    :content-padding="false"
    @confirm="applyFilter"
    @cancel="cancelFilter"
  >
    <div class="my-4 justify-between gap-x-6 px-6 lg:flex">
      <div class="w-full lg:w-1/2">
        <div class="mb-2 font-semibold text-grey-blue">
          {{ $t('filters.selectATouchpoint') }}
        </div>
        <HubTree
          v-model:selection-keys="selectedNodes"
          :value="availableTouchpoints"
          :show-filter="false"
        />
      </div>

      <div
        class="mt-2 min-h-full w-full border-t border-mid-grey p-4 text-grey-blue lg:w-1/2 lg:border-l lg:border-t-0"
      >
        <div class="hidden text-xs font-semibold lg:block">
          {{ $t('filters.selectedTouchpoints') }}
        </div>
        <div class="flex items-center justify-between rounded-md bg-blue-7 p-3 text-xs font-bold lg:mt-5">
          <div class="size-full">
            <div class="flex w-full items-center justify-between">
              <div class="flex items-baseline justify-between">
                {{ selectedTouchpointTreeNodesCount }} {{ $t('filters.selected', selectedTouchpointTreeNodesCount) }}
                <span
                  v-if="selectedTouchpointTreeNodesCount === 0"
                  class="ml-1"
                >({{ $t('filters.showAll') }})</span>
              </div>
              <UButton
                v-if="selectedTouchpointTreeNodesCount > 0"
                size="2xs"
                variant="outline"
                @click="clearFilter"
              >
                {{ $t('clear') }}
              </UButton>
            </div>
            <div
              v-if="selectedTouchpointTreeNodes.length > 0"
              class="mt-4 hidden flex-col gap-1.5 md:flex"
            >
              <div
                v-for="node of selectedTouchpointTreeNodes"
                :key="node.key"
                class="flex items-center justify-between text-sm font-normal text-grey-blue"
              >
                <p>{{ node.label }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </HubModal>
</template>

<script setup lang="ts">
import type { CollectionOfSelectedTreeNodes, TouchpointTreeNode } from '~/types/tree'
// internal types

const props = defineProps<{
  selectedTouchpoints?: Array<string> | string
  availableTouchpoints: Array<TouchpointTreeNode>
}>()
const emit = defineEmits<{
  (e: 'update:selected-touchpoints', selectedTouchpointKeys: Array<string>): void
  (e: 'update:selected-touchpoint-names', selectedTouchpointNames: Array<string> | undefined): void
  (e: 'update:model-value', arg1: boolean): void
}>()

// internal refs
const selectedNodes: Ref<CollectionOfSelectedTreeNodes | undefined> = ref()

// computed refs
const selectedTouchpointKeys: ComputedRef<Array<string>> = computed(() =>
  selectedNodes.value ? getSelectedKeysFromNodes(selectedNodes.value, false) : []
)
const selectedTouchpointTreeNodes: ComputedRef<Array<TouchpointTreeNode>> = computed(() => {
  if (!selectedTouchpointKeys.value) return []

  return props.availableTouchpoints.filter(touchpoint => selectedTouchpointKeys.value.includes(touchpoint.key))
})

const selectedTouchpointTreeNodesCount = computed(() => selectedTouchpointTreeNodes.value.length)

// watchers
watch(
  [() => props, () => props.availableTouchpoints],
  () => {
    if (!props.selectedTouchpoints) {
      emit('update:selected-touchpoint-names', undefined)
      return
    }

    selectedNodes.value = getSelectedNodesFromKeys(props.selectedTouchpoints, props.availableTouchpoints)
    emit(
      'update:selected-touchpoint-names',
      selectedTouchpointTreeNodes.value.map(t => t.label)
    )
  },
  {
    deep: true,
    immediate: true
  }
)

function applyFilter() {
  emit('update:selected-touchpoints', selectedTouchpointKeys.value)
  emit(
    'update:selected-touchpoint-names',
    selectedTouchpointTreeNodes.value.map(t => t.label)
  )
  emit('update:model-value', false)
}

function cancelFilter() {
  if (props.selectedTouchpoints) {
    selectedNodes.value = getSelectedNodesFromKeys(props.selectedTouchpoints, props.availableTouchpoints)
  }
  emit('update:model-value', false)
}

function clearFilter() {
  selectedNodes.value = undefined
}
</script>
